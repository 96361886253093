import Default from '../Components/Dashboard/Default';
import DefaultButton from '../Components/Buttons/Default';
import FlatButton from '../Components/Buttons/FlatBtn/index';
import RaisedButton from '../Components/Buttons/RaisedBtn/index';
import GroupButton from '../Components/Buttons/GroupBtn/index';
import DataTables from '../Components/Tables/DataTable';
import Error404 from '../Components/Pages/ErrorPages/ErrorPage404';
import ProspectosPage from '../Components/Prospectos/ProspectosPage';
import Brokers from '../Components/brokers/Brokers';
import DisponibilidadPage from '../Components/Disponibilidad/DisponibilidadPage';
import RecordatoriosPage from '../Components/Recordatorios/RecordatoriosPage';
import DesarrollosPage from '../Components/Desarrollos/DesarrollosPage';
import MotivosPage from '../Components/Motivos/MotivosPage';
import CanalesPage from '../Components/Canales/CanalesPage';
import EmbudosPage from '../Components/Embudos/EmbudosPage';
import EtapasPage from '../Components/Etapas/EtapasPage';
import RecuperacionPage from '../Components/Recuperacion/RecuperacionPage';
import Zapier from '../Components/Zapier/Zapier';
import Users from '../Components/Usuarios/Users';
import Reports from '../Components/Reports/Reports';

export const routes = [
   {
      path: `/dashboard`,
      Component: <Default />,
   },
   {
      path: `/usuarios`,
      Component: <Users />,
   },
   {
      path: `/prospectos`,
      Component: <ProspectosPage />,
   },
   {
      path: `/brokers`,
      Component: <Brokers />,
   },
   {
      path: `/recordatorios`,
      Component: <RecordatoriosPage />,
   },
   {
      path: `desarrollos`,
      Component: <DesarrollosPage />,
   },
   {
      path: `desarrollos/disponibilidad`,
      Component: <DisponibilidadPage />,
   },
   {
      path: `motivos`,
      Component: <MotivosPage />,
   },
   {
      path: `canales`,
      Component: <CanalesPage />,
   },
   {
      path: `recuperacion`,
      Component: <RecuperacionPage />,
   },
   {
      path: `embudos`,
      Component: <EmbudosPage />,
   },
   {
      path: `etapas`,
      Component: <EtapasPage />,
   },
   {
      path: `reportes`,
      Component: <Reports />,
   },
   {
      path: `zapier`,
      Component: <Zapier />,
   },
   {
      path: `*`,
      Component: <Error404 />,
   },

   // // //Buttons
   {
      path: `/buttons/simplebutton`,
      Component: <DefaultButton />,
   },
   {
      path: `/buttons/flat`,
      Component: <FlatButton />,
   },

   {
      path: `/buttons/raised`,
      Component: <RaisedButton />,
   },
   {
      path: `/buttons/group`,
      Component: <GroupButton />,
   },

   // //Tables
   {
      path: `/table/datatable`,
      Component: <DataTables />,
   },
];
