import React from 'react';
import { Typography, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
//import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
//import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ProspectoMenu = ({
   row,
   onDeleteRow,
   size,
   onEditRow,
   onUpdateStatusRow,
   onUpdateDesarrolloRow,
}) => {
   const [anchorEl, setAnchorEl] = React.useState(null);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };
   const deleteRow = () => {
      if (onDeleteRow) {
         setAnchorEl(null);
         onDeleteRow(row);
      }
   };
   const updateStatusRow = () => {
      if (onUpdateStatusRow) {
         setAnchorEl(null);
         onUpdateStatusRow(row);
      }
   };
   const updateDesarrolloRow = () => {
      if (onUpdateDesarrolloRow) {
         setAnchorEl(null);
         onUpdateDesarrolloRow(row);
      }
   };
   // const editRow = () => {
   //    if (onEditRow) {
   //       setAnchorEl(null);
   //       onEditRow(row);
   //    }
   // };

   return (
      <div>
         <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size={size}>
            <MoreVertIcon />
         </IconButton>
         <Menu
            id="menu"
            // getContentAnchorEl={null}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
            }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {/* <MenuItem onClick={editRow}>
               <ListItemIcon>
                  <EditOutlinedIcon fontSize="small" color="primary" />
               </ListItemIcon>
               <Typography variant="inherit">Editar</Typography>
            </MenuItem> */}

            {row.broker !== 1 && (
               <>
                  <MenuItem onClick={updateStatusRow}>
                     <ListItemIcon>
                        <SyncOutlinedIcon fontSize="small" color="info" />
                     </ListItemIcon>
                     <Typography variant="inherit">Cambiar etapa</Typography>
                  </MenuItem>

                  <MenuItem onClick={updateDesarrolloRow}>
                     <ListItemIcon>
                        <ContentCopyIcon fontSize="small" color="info" />
                     </ListItemIcon>
                     <Typography variant="inherit">Añadir a otro desarrollo</Typography>
                  </MenuItem>
               </>
            )}

            <MenuItem onClick={deleteRow}>
               <ListItemIcon>
                  <CloseOutlinedIcon fontSize="small" color="error" />
               </ListItemIcon>
               <Typography variant="inherit">Eliminar</Typography>
            </MenuItem>
         </Menu>
      </div>
   );
};

export default ProspectoMenu;
