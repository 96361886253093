import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { Form, FormGroup, Input, Label, Row } from 'reactstrap';
import CommonModal from '../UiKits/Modals/common/modal';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Btn } from '../../AbstractElements';
import Request from '../../api/httpClient';
import Select from 'react-select';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';

const DisponibilidadFormEdit = ({ modal, title, toggle, size, reload, departamento }) => {
   const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
   const [estados] = useState([
      { value: 'V', label: 'Vendido' },
      { value: 'D', label: 'Disponible' },
      { value: 'A', label: 'Apartado' },
   ]);
   const [preventas] = useState([
      { value: 0, label: 'NO' },
      { value: 1, label: 'Si' },
   ]);
   const [precio, setPrecio] = useState(departamento.precio);
   const [estado, setEstado] = useState(
      estados.find((item) => item.value === departamento.estado)
   );
   const [preventa, setPreventa] = useState(
      preventas.find((item) => item.value === departamento.preventa)
   );

   const request = new Request();

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitDisabled(true);

      let data = {
         id_departamento: departamento.pkdepto,
         precio: precio,
         estado: estado.value,
         preventa: preventa.value,
      };

      const response = await request.post('/disponibilidad/departamento/update', data);

      if (response && !response.error) {
         toggle();
         setIsSubmitDisabled(false);
         reload('Departamento editado con éxito ');
      } else {
         setIsSubmitDisabled(false);
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };
   return (
      <CommonModal isOpen={modal} title={title} toggler={toggle} size={size}>
         <Modal isOpen={modal} toggle={toggle} size={size} centered>
            <Form onSubmit={handleSubmit}>
               <ModalHeader toggle={toggle}>{title}</ModalHeader>
               <ModalBody style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                  <Row>
                     <Col sm="12" md="12">
                        <div className="project-box" style={{ paddingBottom: '5px' }}>
                           <div>
                              <div>
                                 <FormGroup className="row">
                                    <Label className="col-form-label text-start">
                                       Precio:
                                    </Label>
                                    <Col xl="12">
                                       <Input
                                          value={precio}
                                          id="precio"
                                          type="number"
                                          onChange={(e) => {
                                             setPrecio(e.target.value);
                                          }}
                                          required
                                       />
                                    </Col>
                                 </FormGroup>
                                 <FormGroup className="row">
                                    <Label className="col-form-label text-start">
                                       Estado:
                                    </Label>
                                    <Col xl="12">
                                       <Select
                                          value={estado}
                                          options={estados}
                                          placeholder={'Seleccione...'}
                                          onChange={(estado) => setEstado(estado)}
                                          required
                                       />
                                    </Col>
                                 </FormGroup>
                                 <FormGroup className="row">
                                    <Label className="col-form-label text-start">
                                       Preventa:
                                    </Label>

                                    <Col xl="12">
                                       <Select
                                          value={preventa}
                                          options={preventas}
                                          placeholder={'Seleccione...'}
                                          onChange={(preventa) => setPreventa(preventa)}
                                          required
                                       />
                                    </Col>
                                 </FormGroup>
                              </div>
                           </div>
                        </div>
                     </Col>
                  </Row>
               </ModalBody>
               <ModalFooter>
                  <Btn attrBtn={{ color: 'secondary', onClick: toggle }}>Cancelar</Btn>
                  <Btn
                     attrBtn={{
                        type: 'submit',
                        color: 'primary',
                        disabled: isSubmitDisabled,
                     }}>
                     {isSubmitDisabled ? 'Guardando...' : 'Guardar'}
                  </Btn>
               </ModalFooter>
            </Form>
         </Modal>
      </CommonModal>
   );
};

export default DisponibilidadFormEdit;
